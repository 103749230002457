import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { serializeError } from 'serialize-error';
import {
  _getAssessmentInfo,
  _getCandidateInfo,
  _getAttributes,
  _getTestInfo,
  _getLibTestInfo,
  _setResults,
  _uploadBytes,
  _getCultureAttributes,
  _updateCondition,
  _getSpreadsheets,
  _getSpreadsheetData,
  _updateSpreadsheetData,
  _createSpreadsheetsCandidate,
  _copySpreadSheet,
  _getUserInfo,
  _setVoiceTest,
} from 'utils/firebase/testtaker';
import * as Sentry from '@sentry/react';

const initialState = {};

export const getAssessmentInfo = createAsyncThunk(
  'testtaker/getAssessmentInfo',
  async (payload = {}, { rejectWithValue }) => {
    const { uid, aid } = payload;
    try {
      const doc = await _getAssessmentInfo({ uid, aid });
      return {
        doc,
      };
    } catch (e) {
        Sentry.captureException(e, {extra:{uid, aid}});
        return rejectWithValue(serializeError(e));
    }
  }
);

export const getCandidateInfo = createAsyncThunk(
  'testtaker/getCandidateInfo',
  async (payload = {}, { rejectWithValue }) => {
    const { uid, aid, email } = payload;
    try {
      const doc = await _getCandidateInfo({ uid, aid, email });
      return {
        doc,
      };
    } catch (e) {
        Sentry.captureException(e);
        return rejectWithValue(serializeError(e));
    }
  }
);

export const getAttributes = createAsyncThunk(
  'testtaker/getAttributes',
  async (payload = {}, { rejectWithValue }) => {
    const { sid, lang } = payload;
    try {
      const docs = await _getAttributes({
        sid,
        lang,
      });
      return {
        docs,
      };
    } catch (e) {
        Sentry.captureException(e);
        return rejectWithValue(serializeError(e));
    }
  }
);

export const getCultureAttributes = createAsyncThunk(
  'testtaker/getCultureAttributes',
  async (payload = {}, { rejectWithValue }) => {
    const { sid, lang } = payload;
    try {
      const docs = await _getCultureAttributes({ sid, lang });
      return {
        docs,
      };
    } catch (e) {
        Sentry.captureException(e);
        return rejectWithValue(serializeError(e));
    }
  }
);

export const getTestInfo = createAsyncThunk(
  'testtaker/getTestInfo',
  async (payload = {}, { rejectWithValue }) => {
    const { tid, lang } = payload;
    // console.log(tid + ", " + lang);
    try {
      const doc = await _getTestInfo({ tid, lang });
      return {
        doc,
      };
    } catch (e) {
        Sentry.captureException(e);
        return rejectWithValue(serializeError(e));
    }
  }
);

export const getLibTestInfo = createAsyncThunk(
  'testtaker/getLibTestInfo',
  async (payload = {}, { rejectWithValue }) => {
    const { tid, lang } = payload;
    // console.log(tid + ", " + lang);
    try {
      const doc = await _getLibTestInfo({ tid, lang });
      return {
        doc,
      };
    } catch (e) {
        Sentry.captureException(e);
        return rejectWithValue(serializeError(e));
    }
  }
);

export const getLibTestInfos = createAsyncThunk(
  'testtaker/getLibTestInfos',
  async (payload = {}, { rejectWithValue, dispatch }) => {
    const { tids, lang } = payload;
    try {
      const docs = new Array();
      const tasks = new Array();
      tids.forEach((tid) => {
        tasks.push(
          dispatch(
            getLibTestInfo({
              tid,
              lang,
            })
          )
            .unwrap()
            .then(({ doc }) => {
              docs.push(doc);
            })
        );
      });
      await Promise.allSettled(tasks);

      return {
        docs,
      };
    } catch (e) {
        Sentry.captureException(e);
        return rejectWithValue(serializeError(e));
    }
  }
);

export const getTestInfos = createAsyncThunk(
  'testtaker/getTestInfos',
  async (payload = {}, { rejectWithValue, dispatch }) => {
    const { tids, lang } = payload;
    try {
      const basics = new Array();
      const intermediates = new Array();
      const advanceds = new Array();

      const tasks = new Array();
      tids.forEach((tid) => {
        tasks.push(
          dispatch(
            getTestInfo({
              tid,
              lang,
            })
          )
            .unwrap()
            .then(({ doc: testInfo }) => {
              switch (testInfo?.level_en) {
                case 'Basic':
                case '초급':
                  basics.push(testInfo);
                  break;
                case 'Intermediate':
                case '중급':
                  intermediates.push(testInfo);
                  break;
                case 'Advanced':
                case '고급':
                  advanceds.push(testInfo);
                  break;
                default:
                  basics.push(testInfo);
              }
            })
        );
      });
      await Promise.allSettled(tasks);

      return {
        basics,
        intermediates,
        advanceds,
      };
    } catch (e) {
        Sentry.captureException(e);
        return rejectWithValue(serializeError(e));
    }
  }
);

export const setResults = createAsyncThunk(
  'testtaker/setResults',
  async (payload = {}, { rejectWithValue }) => {
    const { uid, aid, cid, results } = payload;
    try {
      await _setResults({
        uid,
        aid,
        cid,
        results,
      });
      return {
        success: true,
      };
    } catch (e) {
        Sentry.captureException(e);
        return rejectWithValue(serializeError(e));
    }
  }
);

export const uploadBytes = createAsyncThunk(
  'testtaker/uploadBytes',
  async (payload = {}, { rejectWithValue }) => {
    const { uid, aid, cid, sid, fileName, bytes } = payload;
    try {
      const downloadURL = await _uploadBytes({
        uid,
        aid,
        cid,
        sid,
        fileName,
        bytes,
      });

      return {
        downloadURL,
      };
    } catch (e) {
        Sentry.captureException(e);
        return rejectWithValue(serializeError(e));
    }
  }
);

export const setVoiceTest = createAsyncThunk(
  'testtaker/setVoiceTest',
  async (payload = {}, { rejectWithValue }) => {
    const { uid, aid, cid, results } = payload;
    try {
      await _setVoiceTest({
        uid,
        aid,
        cid,
        results,
      });
      return {
        success: true,
      };
    } catch (e) {
        Sentry.captureException(e);
        return rejectWithValue(serializeError(e));
    }
  }
);

export const updateCondition = createAsyncThunk(
  'testtaker/updateCondition',
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { uid, aid, cid, condition, progress } = payload;
      await _updateCondition({
        uid,
        aid,
        cid,
        condition,
        progress,
      });
      return {
        success: true,
      };
    } catch (e) {
        Sentry.captureException(e);
        return rejectWithValue(serializeError(e));
    }
  }
);

export const getSpreadsheets = createAsyncThunk(
  'testtaker/getSpreadsheets',
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { spreadsheetId } = payload;
      const { properties } = await _getSpreadsheets({
        spreadsheetId,
      });
      return {
        properties,
      };
    } catch (e) {
        Sentry.captureException(e);
        return rejectWithValue(serializeError(e));
    }
  }
);

export const getSpreadsheetData = createAsyncThunk(
  'testtaker/getSpreadsheets',
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { spreadsheetId } = payload;
      const datas = await _getSpreadsheetData({
        spreadsheetId,
      });
      return {
        datas,
      };
    } catch (e) {
        Sentry.captureException(e);
        return rejectWithValue(serializeError(e));
    }
  }
);

export const createSpreadsheetsCandidate = createAsyncThunk(
  'testtaker/createSpreadsheetsCandidate',
  async (payload = {}, { rejectWithValue }) => {
    try {
      const { originalSpreadsheetsId, uid, aid, cid } = payload;
      // const { spreadsheetsId, spreadsheetsUrl } =
      //   await _createSpreadsheetsCandidate({
      //     properties,
      //   });
      const { data, status } = await _copySpreadSheet({
        spreadsheetId: originalSpreadsheetsId,
        uid,
        aid,
        cid,
      });

      if (status === 200) {
        return {
          spreadsheetsId: data?.id,
        };
      }
      return {
        spreadsheetsId: '',
      };
    } catch (e) {
        Sentry.captureException(e);
        return rejectWithValue(serializeError(e));
    }
  }
);

export const getOBPITest = createAsyncThunk(
  'testtaker/getOBPITest',
  async (payload = {}, { rejectWithValue }) => {
    const { uid, aid, cid, lang } = payload;
    try {
      const obpitest = await axios.get(
        `${process.env.REACT_APP_OBPI_GET_TEST_URL}/cambridge/questions`,
        {
          headers: { accept: 'application/json' },
          params: { uid, aid, cid, lang: 'ko' },
        }
      );
      return obpitest.data;
    } catch (e) {
        Sentry.captureException(e);
        return rejectWithValue(serializeError(e));
    }
  }
);

export const getOBPITestResult = createAsyncThunk(
  'testtaker/getOBPITestResult',
  async (payload = {}, { rejectWithValue }) => {
    const { uid, aid, cid, item } = payload;
    try {
      const obpitestres = await axios.post(
        `${process.env.REACT_APP_OBPI_GET_TEST_URL}/firestore/cambridge/scores`,
        item,
        {
          params: {
            db: process.env.REACT_APP_OBPI_GET_TEST_DB,
            uid,
            aid,
            email: cid,
          },
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        }
      );
      return obpitestres.data;
    } catch (e) {
        Sentry.captureException(e);
        return rejectWithValue(serializeError(e));
    }
  }
);

export const getKHAITestResult = createAsyncThunk(
  'testtaker/getKHAITestResult',
  async (payload = {}, { rejectWithValue }) => {
    const { khaitype, lang, item, email } = payload;
    try {
      const khaitestres = await axios.post(
        `${process.env.REACT_APP_OBPI_GET_TEST_URL}/munchskill/khai/result`,
        item,
        {
          params: { khaitype, lang, id: email },
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        }
      );
      return khaitestres.data;
    } catch (e) {
        Sentry.captureException(e);
        return rejectWithValue(serializeError(e));
    }
  }
);

export const getUserInfo = createAsyncThunk(
  'testtaker/getUserInfo',
  async (payload = {}, { rejectWithValue, getState }) => {
    try {
      const uid = payload;
      const doc = await _getUserInfo({ uid });
      const { logoURL } = doc || {};
      return {
        doc,
        logoURL,
      };
    } catch (e) {
        Sentry.captureException(e);
        return rejectWithValue(serializeError(e));
    }
  }
);

export const setVoiceTestThunk = createAsyncThunk(
  'testtaker/setVoiceTestThunk',
  async (payload = {}, { rejectWithValue }) => {
    const { videourl, lang } = payload;
    try {
      const voiceCheck = await axios.get(
        'https://qadflf4qtg.execute-api.us-west-2.amazonaws.com/Prod/voicecheck',
        {
          headers: { accept: 'application/json' },
          params: {
            videourl,
            lang,
          },
        }
      );
      return voiceCheck.data;
    } catch (e) {
        Sentry.captureException(e);
        return rejectWithValue(serializeError(e));
    }
  }
);

export const setSpeechToText = createAsyncThunk(
  'testtaker/getSpeechToText',
  async (payload = {}, { rejectWithValue }) => {
    const { uid, aid, cid, tid, voiceurl, lang } = payload;
    try {
      const speechToText = await axios.get(
        `${process.env.REACT_APP_AWS_STT_API}/voice2text`,
        {
          headers: { accept: 'application/json' },
          params: {
            uid,
            aid,
            cid,
            tid,
            voiceurl,
            lang,
          },
        }
      );
      return speechToText.data;
    } catch (e) {
        Sentry.captureException(e);
        return rejectWithValue(serializeError(e));
    }
  }
);

export const retrySetSpeechToText = createAsyncThunk(
  'testtaker/retrySetSpeechToText',
  async (payload = {}, { rejectWithValue, dispatch }) => {
    const { uid, aid, cid, tid, voiceurl, lang, retryCount } = payload;
    try {
      const speechToText = await axios.get(
        `${process.env.REACT_APP_AWS_STT_API}/voice2text`,
        {
          headers: { accept: 'application/json' },
          params: {
            uid,
            aid,
            cid,
            tid,
            voiceurl,
            lang,
          },
        }
      );

      if (speechToText.data.message !== 'done' && retryCount < 3) {
        dispatch(
          retrySetSpeechToText({
            uid,
            aid,
            cid,
            tid,
            voiceurl,
            lang,
            retryCount: retryCount + 1,
          })
        );
      }

      return speechToText.data;
    } catch (e) {
        Sentry.captureException(e);
        console.error('Speech to text conversion failed:', e);
      if (retryCount < 3) {
        dispatch(
          retrySetSpeechToText({
            uid,
            aid,
            cid,
            tid,
            voiceurl,
            lang,
            retryCount: retryCount + 1,
          })
        );
      } else {
        return rejectWithValue(serializeError(e));
      }
    }
  }
);

export const setEssayAnswer = createAsyncThunk(
  'testtaker/getEssayAnswer',
  async (payload = {}, { rejectWithValue }) => {
    const { db, uid, aid, cid, tid, answer, lang, question, type } = payload;
    try {
      const essayAnswer = await axios.post(
        `${process.env.REACT_APP_FAST_API_URL}/gpt/assess`,
        { db, type, question, answer },
        {
          headers: { accept: 'application/json' },
          params: {
            uid,
            aid,
            cid,
            tid,
            lang,
          },
        }
      );
      return essayAnswer.data;
    } catch (e) {
        Sentry.captureException(e);
        return rejectWithValue(serializeError(e));
    }
  }
);

export const retrySetEssayAnswer = createAsyncThunk(
  'testtaker/retrySetEssayAnswer',
  async (payload = {}, { rejectWithValue, dispatch }) => {
    const { uid, aid, cid, tid, answer, lang, retryCount, db, question, type } =
      payload;
    try {
      const essayAnswer = await axios.post(
        `${process.env.REACT_APP_FAST_API_URL}/gpt/assess`,
        { db, type, question, answer },
        {
          headers: { accept: 'application/json' },
          params: {
            uid,
            aid,
            cid,
            tid,
            lang,
          },
        }
      );

      if (essayAnswer.data.res !== 1 && retryCount < 3) {
        dispatch(
          retrySetEssayAnswer({
            db,
            uid,
            aid,
            cid,
            tid,
            answer,
            question,
            lang,
            type: 'video',
            retryCount: retryCount + 1,
          })
        );
      }
      return essayAnswer.data;
    } catch (e) {
        Sentry.captureException(e);
        console.error('answer conversion failed:', e);
      if (retryCount < 3) {
        dispatch(
          retrySetEssayAnswer({
            db,
            uid,
            aid,
            cid,
            tid,
            answer,
            question,
            type: 'video',
            lang,
            retryCount: retryCount + 1,
          })
        );
      } else {
        return rejectWithValue(serializeError(e));
      }
    }
  }
);

export const postEnglishQuestion = createAsyncThunk(
  'testtaker/postEnglishQuestion',
  async (payload = {}, { rejectWithValue, dispatch }) => {
    const { postQuestionData } = payload;

    try {
      const englishQuestion = await axios.post(
        `${process.env.REACT_APP_FAST_API_URL}/munchskill/evalish`,
        {
          headers: {
            accept: 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': '*',
            'Access-Control-Allow-Headers': '*',
          },
          data: postQuestionData,
        }
      );
      return englishQuestion.data;
    } catch (e) {
        Sentry.captureException(e);
        return rejectWithValue(serializeError(e));
    }
  }
);

export const postChineseQuestion = createAsyncThunk(
  'testtaker/postChineseQuestion',
  async (payload = {}, { rejectWithValue, dispatch }) => {
    const { postQuestionData } = payload;

    try {
      const chineseQuestion = await axios.post(
        `${process.env.REACT_APP_FAST_API_URL}/munchskill/evalchi`,
        {
          headers: {
            accept: 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': '*',
            'Access-Control-Allow-Headers': '*',
          },
          data: postQuestionData,
        }
      );
      return chineseQuestion.data;
    } catch (e) {
        Sentry.captureException(e);
        return rejectWithValue(serializeError(e));
    }
  }
);

export const postJapaneseQuestion = createAsyncThunk(
  'testtaker/postJapaneseQuestion',
  async (payload = {}, { rejectWithValue, dispatch }) => {
    const { postQuestionData } = payload;

    try {
      const japaneseQuestion = await axios.post(
        `${process.env.REACT_APP_FAST_API_URL}/munchskill/evaljap`,
        {
          headers: {
            accept: 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': '*',
            'Access-Control-Allow-Headers': '*',
          },
          data: postQuestionData,
        }
      );
      return japaneseQuestion.data;
    } catch (e) {
        Sentry.captureException(e);
        return rejectWithValue(serializeError(e));
    }
  }
);

export const postKoreanQuestion = createAsyncThunk(
  'testtaker/postKoreanQuestion',
  async (payload = {}, { rejectWithValue, dispatch }) => {
    const { postQuestionData } = payload;

    try {
      const japaneseQuestion = await axios.post(
        `${process.env.REACT_APP_FAST_API_URL}/munchskill/evalkor`,
        {
          headers: {
            accept: 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': '*',
            'Access-Control-Allow-Headers': '*',
          },
          data: postQuestionData,
        }
      );
      return japaneseQuestion.data;
    } catch (e) {
        Sentry.captureException(e);
        return rejectWithValue(serializeError(e));
    }
  }
);

export const getEnglishSpeakingQuestion = createAsyncThunk(
  'testtaker/getEnglishSpeakingQuestion',
  async (payload = {}, { rejectWithValue }) => {
    const { level } = payload;

    try {
      const englishQuestion = await axios.get(
        `${process.env.REACT_APP_FAST_API_URL}/munchskill/speaking/${level}`,
        {
          headers: {
            accept: 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': '*',
            'Access-Control-Allow-Headers': '*',
          },
        }
      );
      return englishQuestion.data;
    } catch (e) {
        Sentry.captureException(e);
        return rejectWithValue(serializeError(e));
    }
  }
);

export const testtakerSlice = createSlice({
  name: 'testtaker',
  initialState,
  reducers: {},
  extraReducers: {},
});

export const {} = testtakerSlice.actions;

export default testtakerSlice.reducer;
